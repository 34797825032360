.head {
    height: 200px;
    background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url('https://pbs.twimg.com/media/CuEd7vAW8AANoXA.png');
    width: 100;
    color: #fff;
    position: relative;
}
.head h2 {
    position: absolute;
    margin: 30px;
    font-size: xx-large;
}
.pageBannerT {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding : 180px -0px;
    background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url('https://img.batiweb.com/repo-images/product/346711/leica-station-ts16-2.jpg'); 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
}

.pageBannerT .imageLayer {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
}
.pageBannerT::after {
    content: "";
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-0.5deg), skewX(0.5deg);
    -webkit-transform: skewY(-0.5deg), skewX(0.5deg);
    -moz-transform: skewY(-0.5deg), skewX(0.5deg);
    -ms-transform: skewY(-0.5deg), skewX(0.5deg);
}
.pageBannerT h1 {
    font-size: 48px;
    margin-bottom: 20px;
}
.pageBannerT .subText {
    position: relative;
}
.pageBannerT .subText li:first-child {
    margin-left: 0;
}
.pageBannerT .subText li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;

}

.pageBannerT .subText li a {
    color: #ffffff;
    padding: 10px;
}

.subText li.act a {
    color: #6F140C;
    font-size: 30px;
}

@media only screen and (max-width: 1139px) {
    .pageBannerT {
        padding: 100px 0px 120px;
    }

}