.pageBannerD {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding : 180px -0px;
    background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url('https://www.intel.com/content/dam/www/public/us/en/images/photography-consumer/4x3/drones/falcon-8-flying-away-4x3.jpg.rendition.intel.web.480.360.jpg'); 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
}

.pageBannerD .imageLayer {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
}
.pageBannerD::after {
    content: "";
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-0.5deg), skewX(0.5deg);
    -webkit-transform: skewY(-0.5deg), skewX(0.5deg);
    -moz-transform: skewY(-0.5deg), skewX(0.5deg);
    -ms-transform: skewY(-0.5deg), skewX(0.5deg);
}
.pageBannerD h1 {
    font-size: 48px;
    margin-bottom: 20px;
}
.pageBannerD .subText {
    position: relative;
}
.pageBannerD .subText li:first-child {
    margin-left: 0;
}
.pageBannerD .subText li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;

}

.pageBannerD .subText li a {
    color: #ffffff;
    padding: 10px;
}

.subText li.act a {
    color: #6F140C;
    font-size: 30px;
}
.text ul li {
    color: #fff
}

@media only screen and (max-width: 1139px) {
    .pageBannerD {
        padding: 100px 0px 120px;
    }

}


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  ul {
      list-style-type: none;
  }
  
  a {
      text-decoration: none;
      color:#333;
  
  }
  
  h1, h2 {
      font-weight: 300;
      line-height: 1.2;
      margin: 10px ;
  }
  
  p {
      margin: 10px ;
      color: #fff;
  }
  
  img {
      width: 100%;
  }
  
  .containerdiv {
      display: block;
      margin: 0 auto;
  }
  
  .aproposD {
      display: flex;
      flex:1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 30px;
      height:100%;
      padding: 10px;
      border-bottom:2px solid #fff ;
  }
  .flex_rowD {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100%
  }
  .flex_rowD h1 {
      font-size: 30px;
  }
  .flex_rowD .text  {
      margin-left: 30px;
      padding-left: 15px ;
      border-left: 1px solid gray;
      margin-bottom: 20px;
  }
  .flex_rowD .text ul li  {
      list-style-type: square;
      color : #fff;
      margin-left:  10px;
  }
  .img_container {
      position: relative;
      padding: 20px;
      margin-top: 10px;
  }
  .img_container img {
      height: 100%;
      width: 100%;
      margin: 10px;
  }
  
  .textTwo {
      margin-right: 30px;
      padding-right: 15px ;
      border-right: 1px solid gray;
  }
  .textTwo a {
      text-decoration: underline;
  }

