.pageBannerM {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding : 180px -0px;
    background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url('https://www.topcon.co.jp/en/positioning/products/images/3dscanner/IP-S3HD1_img_E/IP-S3HD1_001_E.jpg'); 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
}
.apropos {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px;
    height:100%;
    padding: 10px;
    border-bottom:2px solid #000 ;
}
.pageBannerM .imageLayer {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
}
.pageBannerM::after {
    content: "";
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-0.5deg), skewX(0.5deg);
    -webkit-transform: skewY(-0.5deg), skewX(0.5deg);
    -moz-transform: skewY(-0.5deg), skewX(0.5deg);
    -ms-transform: skewY(-0.5deg), skewX(0.5deg);
}
.pageBannerM h1 {
    font-size: 48px;
    margin-bottom: 20px;
}
.pageBannerM .subText {
    position: relative;
}
.pageBannerM .subText li:first-child {
    margin-left: 0;
}
.pageBannerM .subText li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;

}

.pageBannerM .subText li a {
    color: #ffffff;
    padding: 10px;
}

.subText li.act a {
    color: #6F140C;
    font-size: 30px;
}
.flex_row .text {
    margin-left: 30px;
    padding-left: 15px ;
    border-left: 1px solid gray;
    font-weight: 400;
    margin-bottom: 20px;
    color: #000;
}
.flex_row .text ul li  {
    list-style-type: square;
    color : #000;
    margin-left:  10px;
}

@media only screen and (max-width: 1139px) {
    .apropos {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}