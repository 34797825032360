.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  .container img {
    width: 100%;
    padding: 20px;
  }
  .pageBannerN {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding: 180px -0px;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
      url("https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
  .apropos {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px;
    height: 100%;
    padding: 10px;
    border-bottom: 2px solid #000;
  }
  .pageBannerN .imageLayer {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
  }
  .pageBannerN::after {
    content: "";
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-0.5deg), skewX(0.5deg);
    -webkit-transform: skewY(-0.5deg), skewX(0.5deg);
    -moz-transform: skewY(-0.5deg), skewX(0.5deg);
    -ms-transform: skewY(-0.5deg), skewX(0.5deg);
  }
  .pageBannerN h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  .pageBannerN .subText {
    position: relative;
  }
  .pageBannerN .subText li:first-child {
    margin-left: 0;
  }
  .pageBannerN .subText li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }
  
  .pageBannerN .subText li a {
    color: #ffffff;
    padding: 10px;
  }
  
  .subText li.act a {
    color: #6f140c;
    font-size: 30px;
  }
  .text {
    margin-left: 30px;
    padding-left: 15px;
    border-left: 1px solid gray;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .text ul li {
    list-style-type: square;
    color: #000;
    margin-left: 10px;
  }
  .classText {
    margin-top: 20px;
  }
  .classText {
    padding-top: 5px;
    padding-left: 15px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;
  }
  
  .classText h1 {
    font-size: 30px;
    margin-top: 10px;
  }
  .paragraph {
    margin-bottom: 10px;
    margin-left: auto;
  }
  
  .classText ul li {
    list-style-type: square;
    color: #000;
    margin-left: 20px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .button {
    display: inline-block;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .button:hover {
      background-color: #fff;
      color: black
  }
  @media only screen and (max-width: 1139px) {
    .apropos {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .container img {
      width: 100%;
      height: 100%;
      padding: 0 10px;
    }
  }
  