
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
  color: #333;
  line-height: 1.6;
}
ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color:#333;

}

h1, h2 {
    font-weight: 300;
    line-height: 1.2;
    margin: 10px ;
}

p {
    margin: 10px ;
}

img {
    width: 100%;
}

.containerdiv {
    display: block;
    margin: 0 auto;
}

.apropos {
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px;
    height:100%;
    padding: 10px;
    border-bottom:2px solid #fff ;
}
.flex_row {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%
}

.flex_row p {
    color: #000
}
.flex_row h1 {
    font-size: 30px;
}
.text {
    margin-left: 30px;
    padding-left: 15px ;
    border-left: 1px solid gray;
    font-weight: 400;
    margin-bottom: 20px;
}
.text ul li  {
    list-style-type: square;
    color : #fff;
    margin-left:  10px;
}
.img_container {
    position: relative;
    padding: 20px;
    margin-top: 10px;
}
.img_container img {
    height: 100%;
    width: 100%;
    margin: 10px;
}

.textTwo {
    margin-right: 30px;
    padding-right: 15px ;
    border-right: 1px solid gray;
    font-weight: 400;
    color: #000
}
.textTwo a {
    text-decoration: underline;
}
.pageBannerA {
    position: relative;
    color: #ffffff;
    text-align: center;
    padding : 180px -0px;
    background-image: linear-gradient(rgba(4,9,30,0.7), rgba(4,9,30,0.7)), url('https://img.freepik.com/free-photo/engineer-meeting-architectural-project-working-with-partner_1421-70.jpg?w=740'); 
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
}

.pageBannerA .imageLayer {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
}
.pageBannerA::after {
    content: "";
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-0.5deg), skewX(0.5deg);
    -webkit-transform: skewY(-0.5deg), skewX(0.5deg);
    -moz-transform: skewY(-0.5deg), skewX(0.5deg);
    -ms-transform: skewY(-0.5deg), skewX(0.5deg);
}
.pageBannerA h1 {
    font-size: 48px;
}
.pageBannerA .subText {
    position: relative;
}
.pageBannerA .subText li:first-child {
    margin-left: 0;
}
.pageBannerA .subText li {
    position: relative;
    display: inline-block;
    line-height: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;

}

.pageBannerA .subText li a {
    color: #ffffff;
    padding: 10px;
}

.subText li.act a {
    color: #6F140C;
    font-size: 20px;
}
@media only screen and (max-width: 1139px) {
    .pageBannerA {
        padding: 100px 0px 120px;
    }
    .apropos {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
    }
    .flex_row {
        width: 90%;
        margin: 10px;
    }
    .textTwo {
        border-right: none;
        margin-left: 30px;
        padding-left: 15px ;
        font-weight: 400;
    }
    .img_container {
        left: 0;
    }
}

@media only screen and (max-width: 1139px) {
    .apropos {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}